import { render, staticRenderFns } from "./home2.vue?vue&type=template&id=2bd0ca53&"
import script from "./home2.vue?vue&type=script&lang=js&"
export * from "./home2.vue?vue&type=script&lang=js&"
import style0 from "./home2.vue?vue&type=style&index=0&id=2bd0ca53&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports